import React, { useEffect } from "react"
import { navigate } from "gatsby"
import LoaderScreen from "../components/LoaderScreen"
import { ThemeProvider } from "styled-components"
import { tiberioBlack } from "../themes"
import { GlobalStyle } from "../components/layout/layout"

const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return "en"
  }

  const lang =
    navigator && navigator.language && navigator.language.split("-")[0]
  if (!lang) return "en"

  switch (lang) {
    case "it":
      return "it"
    default:
      return "en"
  }
}

const IndexPage = () => {
  useEffect(() => {
    const urlLang = getRedirectLanguage()

    navigate(`/${urlLang}/`, { replace: true })
  }, [])

  return (
    <ThemeProvider theme={tiberioBlack}>
      <GlobalStyle />
      <LoaderScreen />
    </ThemeProvider>
  )
}

export default IndexPage
