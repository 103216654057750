import React from 'react'
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import {LoaderContainer} from "./StyledLoader";

const LoaderScreen = () =>{
    return(
        <section>
            <LoaderContainer>
                <Loader type="ThreeDots" color="#FFFFFF" height={80} width={80} />
            </LoaderContainer>
        </section>

    )
}


export default LoaderScreen
